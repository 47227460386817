import React from "react";

function RegisterLogin() {
    const handleClignotant = () => {
        let pro_features=document.getElementById('pro-features');
        if(pro_features.classList.contains('active')) {
            pro_features.classList.remove("clignoter");
        }else {
            pro_features.classList.add("clignoter");
        }
    }

    return <div>
        <ul className="pro-features clignoter" id="pro-features" onClick={()=>handleClignotant()}>
            <a className="get-pro" ><i className="fa fa-plus-circle" aria-hidden="true"></i></a>
            <div className="button">
                <span className="btn"><a href='/register'>S'enregistrer</a></span>
                <span className="btn"><a target="_blank" href='https://client.youthfp.cm/login'>Se Connécter</a></span>
                <span className="btn"><a href='/denonciation'>Dénonciation</a></span>
            </div>
            <hr/>
        </ul>
    </div>;

}

export default RegisterLogin;