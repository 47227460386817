import React from "react";

function Partnaires() {

    return <div className="clients overlay">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12" style={
                    {
                        textAlign: "center"
                    }
                }>
                    <div className="owl-carousel clients-slider">
                        <div className="single-clients">
                            <img src="img/logo/logounfpa.png" alt="UNFPA" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logoafriyan.png" height="168px" alt="AFRIYAN" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logocamnafaw.png" alt="CAMNAFAW" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logocnjc.png" height={"10px"} alt="cnjc" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logoReCaJ.png" alt="RECAJ" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logoreseaujeunes.png" alt="RE" />
                        </div>
                        <div className="single-clients">
                            <img src="img/logo/logoministere.png" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

}

export default Partnaires;