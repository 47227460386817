import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";
import {Button, Form, Input, Select, Space} from "antd";

function Register() {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const submitRegisterForm = (event) => {

        let data = {
            prenom: event.prenom,
            nom: event.nom,
            email: event.email,
            numero_telephone: event.numero_telephone,
            sexe: event.sexe,
            pwd: event.pwd
        };
        setLoading(true)
        document.getElementById('preloader').classList.remove('preloader-deactivate');
        axiosInstance().post('user/register', data)
            .then(function (response) {
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick("Success","success");
                form.resetFields();
                setLoading(false);
                window.open("https://client.youthfp.cm/login", "_blank");
            })
            .catch(function (error) {
                setLoading(false);
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick("Echec","error");
            });
    };

    useEffect(() => {
        document.title = "S'enregistrer";
    }, []);

    return (
        <section className="contact-us section">
            <div className="container">
                <div className="inner">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-us-left">
                                <div id="register"></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-us-form">
                                <h2>S'enregistrer</h2>
                                <p>Creer un compte.</p>
                                <Form onFinish={submitRegisterForm} form={form}>
                                    <Form.Item
                                        label="Nom"
                                        name="nom"
                                        rules={
                                            [
                                                { required: true, message: 'Veuillez entrer votre nom' },
                                                {type:"string", message:"Veuillez entrer votre nom !"}
                                            ]}
                                        hasFeedback>
                                        <Input placeholder="Entrer votre nom"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Prenom"
                                        name="prenom"
                                        rules={
                                            [
                                                { required: true, message: 'Veuillez entrer votre Prenom' },
                                                {type:"string", message:"Veuillez entrer votre Prenom !"}
                                            ]}
                                        hasFeedback>
                                        <Input placeholder="Entrer votre Prenom"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="E-Mail"
                                        name="email">
                                        <Input placeholder="Entrer votre Email"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="N°Tel"
                                        name="numero_telephone"
                                        rules={[
                                            { required: true, message: 'Veuillez entrer le n°tel' },
                                            {type:"string", message:"Entrer le numéro de téléphone!"},
                                            {len:9, message:"Veillez saisir un numéro de téléphone valide !"},
                                            { pattern: /^6[0-9]{8}$/, message: "Le numéro de téléphone doit commencer par 6 et contenir 9 chiffres !" }
                                        ]}
                                        hasFeedback>
                                        <Input placeholder="entrer n°Tel"/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Sexe"
                                        name="sexe"
                                        rules={
                                            [
                                                { required: true, message: "Selectionner le sexe" },

                                            ]}
                                        hasFeedback>
                                        <Select placeholder="Choisir le genre">
                                            <Select.Option value="HOMME">HOMME</Select.Option>
                                            <Select.Option value="FEMME">FEMME</Select.Option>
                                        </Select>
                                    </Form.Item>


                                    <Form.Item
                                        label="Mot de Passe"
                                        name="pwd"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Saisir votre mot de passe',
                                            },
                                            {
                                                min: 8,
                                                message: 'Le mot de passe doit avoir au minimum 8 caracteres.',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder="Entrer votre Mot de Passe" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirmer votre mot de passe"
                                        name="confirm"
                                        dependencies={['pwd']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Confirmer votre mot de passe',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('pwd') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Le mot de passe ne correspond pas!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirmer votre mot de passe" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Space style={{float:'right'}}>
                                            <Button type='primary' htmlType='submit' disabled={loading}>
                                                S'enregistrer
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;
