import React from "react";
import {NavLink} from "react-router-dom";

function AboutLink() {


    return <section className="call-action overlay" data-stellar-background-ratio="0.5">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="content">
                        <h2>YOUTHFP</h2>
                        <p>Youth For Family Planning</p>
                        <div className="button">
                            <NavLink to="/about" className="btn second">En savoir plus<i
                                className="fa fa-long-arrow-right"></i></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;

}

export default AboutLink;