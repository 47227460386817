import React from "react";

export default function ErrorClient(){

    return <section className="error-page section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                    <div className="error-inner">
                        <h1>ERREUR<span>Ressource non trouvee</span></h1>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}