import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import articleImg from "../../img/cover/BMD-3398-760x400.png";
import section from "../../img/section-img.png";

function ListArticles() {
    const {name} = useParams();
    const [articles, setArticles] = useState([]);

    const fetchArticle = ()=>{
        document.getElementById('preloader').classList.remove('preloader-deactivate');
        let dataList = ['SANTE_SEXUELLE','GROSSESSE_PRECOSE','HYGENE_MENSTRUELLE','VIH_IST','DROGUE_STUPEFIANT','VVG','IVG','PLANNING_FAMILLIAL','YOUNGLEAD']
        if(dataList.fill(name).length<=0){
            window.location.href='/'
        }
        axiosInstance().get('api/article/get-by-categorie/'+ name)
            .then(function (response) {
                let data = response.data;
                let fetchedArticles = [];
                if (data != null) {
                    for (let i = 0; i < data.length; i++) {
                        try {
                            fetchedArticles.push(
                                <div className="col-lg-4 col-md-6 col-12" key={data[i].id}>
                                    <div className="single-news">
                                        <div className="news-head">
                                            <img src={data[i].phote == null ? articleImg : data[i].phote} alt="Image de couverture" />
                                        </div>
                                        <div className="news-body">
                                            <div className="news-content">
                                                <div className="date">Auteur: {data[i].author}</div>
                                                <h2><NavLink to={"/read/" + data[i].id}>Titre: {data[i].title}</NavLink></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } catch (e) {
                        }
                    }
                    setArticles(fetchedArticles);
                    document.getElementById('preloader').classList.add('preloader-deactivate');
                } else {
                    fetchedArticles.push(
                        <div id="error">
                            <p>Il n'y a pas d'articles pour le moment</p>
                        </div>
                    );
                    setArticles(fetchedArticles);
                    document.getElementById('preloader').classList.add('preloader-deactivate');
                }
            })
            .catch(function (error) {
                document.getElementById('preloader').classList.add('preloader-deactivate');
            });
    }
    useEffect(
        ()=>{
            document.title='Article'
            fetchArticle();
        },[]
    )
    return (
        articles.length === 0 ? (
            <section className="blog section" id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>ARTICLES</h2>
                                <img src={section} alt="#" />
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h2>Vide</h2>
                    </div>
                </div>
            </section>
        ) : (
            <section className="blog section" id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>ARTICLES</h2>
                                <img src={section} alt="#" />
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {articles}
                    </div>
                </div>
            </section>
        )
    );

}

export default ListArticles;