import './App.css';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import RegisterLogin from "./components/registerlogin/RegisterLogin";
import AddDenonciations from "./components/denonciations/addDenonciation/AddDenonciations";
import About from "./components/about/About";
import ListArticles from "./components/articles/listArticles/ListArticles";
import {CustomToast} from "./components/toast/CustomToast";
import React from "react";
import Register from "./components/register/Register";
import ReadArticle from "./components/articles/readArticle/ReadArticle";
import Services from "./components/services/Services";
import Quizz from "./components/quizz/Quizz";
import CentrePartenaires from "./components/centrePartenaires/CentrePartenaires";
import CarteLeaflet from "./components/carte/CarteLeaflet";
import ErrorClient from "./components/error/404/ErrorClient";
import QuizzList from "./components/quizz/QuizzList";
import Background from "./components/background/Background";

const route = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <Error />,
        children: [
            {
              path: '',
              element: <Home />
            },
            {
                path: 'denonciation',
                element: <AddDenonciations />
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'register',
                element: <Register />
            },
            {
                path: 'work/:name',
                element: <ListArticles />
            },
            {
                path: 'read/:id',
                element: <ReadArticle />
            },
            {
                path: 'quizz',
                children:[
                    {
                        path: '',
                        element: <QuizzList />
                    },
                    {
                        path: ':id',
                        element: <Quizz />
                    }
                ]
            },
            {
                path: 'cp/:id',
                element: <CentrePartenaires />
            },
            {
                path: 'service',
                element: <Services />
            },
            {
                path: 'carte',
                element: <CarteLeaflet />
            }
        ]
    },
]);

function Root() {
    return <>
        <Background />
        <Header />
        <>
            <RegisterLogin />
            <Outlet />
            <CustomToast />
        </>
        <Footer />
        <hr/>
    </>

}

function Error() {
    return <>
        <Background />
        <Header />
        <>
            <ErrorClient />
        </>
        <Footer />
    </>

}
function App() {
  return <RouterProvider router={route} />;
}

export default App;
