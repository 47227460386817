import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

function Themes() {

    const [mesThemes, setMesThemes] = useState([]);
    const fetchTheme = () => {
      axiosInstance().get('api/theme').then(response => {
          let data = response.data;
          setMesThemes(data)
      }).catch(error => {})
    };
    useEffect(
        ()=>{
            fetchTheme();
        },[]
    )

    return <>
        {
            mesThemes.length === 0 ? <></> :
                <section className="services section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>THEMES ABORDES</h2>
                                    <img src="/img/section-img.png" alt="#" />
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                mesThemes.map((value, index) => {
                                    return <div key={value.id} className="col-lg-4 col-md-6 col-12">
                                        <div className="single-service">
                                            <i className="fa fa-arrow-right"></i>
                                            <h4><a>{value.title.toString().toUpperCase()}</a></h4>
                                            <p></p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>
        }
    </>;

}

export default Themes;