import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";

function Missions() {
    const [missions, setMissions] = useState([]);

    const fetchMissions = ()=>{
        axiosInstance().get('api/missions')
            .then(function (response) {
                let data = response.data;
                let fetchedValeurs = [];
                if (data != null) {
                    for (let i = 0; i < 3; i++) {
                        try {
                            fetchedValeurs.push(
                                <div className="col-lg-4 col-md-6 col-12" key={data[i].id}>
                                    <div className="single-schedule first">
                                        <div className="inner">
                                            <div className="icon"><i className="fa fa-diamond"></i></div>
                                            <div className="single-content"><span>MISSIONS</span><h4>{data[i].title}</h4></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } catch (e) {
                        }
                    }
                    setMissions(fetchedValeurs);
                } else {
                    setMissions([]);
                }
            })
            .catch(function (error) {
            });

    }
    useEffect(
        ()=>{
            fetchMissions();
        },
        []
    )
    return (missions.length === 0) ? (<></>) : (<section className="schedule">
            <div className="container">
                <div className="schedule-inner">
                    <div className="row">{missions}</div>
                </div>
            </div>
        </section>)
    ;

}
export default Missions;