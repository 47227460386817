import React, {useEffect, useState} from "react";

import {Editor} from "react-draft-wysiwyg";
import {convertFromRaw, EditorState} from "draft-js";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {Empty, Spin} from "antd";

function About() {

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const nav = useNavigate();
    const [aPropos, setAPropos] = useState(null);
    const [loading, setLoading] = useState(true);

    const getAProposById = () => {
        axiosInstance().get(
            "api/apropos/list"
        ).then((result)=> {
            if(result.data != null){
                setAPropos(result.data)
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(result.data.contenu))));
            }
            setLoading(false);
        }).catch((result)=> {
            setLoading(false);
        });
    }

    useEffect(
        ()=>{
            setLoading(true)
            getAProposById();
            document.title='A propos';
        },[]
    );

    const returnValue = aPropos ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <hr />
            <div style={{padding: "5px", marginTop: '5px', width: '100%'}}>
                <Editor
                    toolbarClassName="toolbar-class1"
                    readOnly={true}
                    editorState={editorState}
                    toolbar={
                        {
                            options: []
                        }
                    }
                />
            </div>
        </Spin>
    </>


    return <section className="services section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>A PROPOS</h2>
                        <img src="/img/section-img.png" alt="#" />
                            <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                    {returnValue}
                </div>
            </div>
        </div>
    </section>;

}

export default About;