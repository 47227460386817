import React from "react";

function Realisations() {

    return <section className="portfolio section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Nos Realisations</h2>
                        <img src="img/section-img.png" alt="#" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="owl-carousel portfolio-slider">
                        <div className="single-pf">
                            <img src="img/cover/1.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/2.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/3.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/4.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/5.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/103.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/6.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/9.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/10.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/12.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/14.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/15.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/101.jpg" alt="#" />

                        </div>
                        <div className="single-pf">
                            <img src="img/cover/98.jpg" alt="#" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;

}

export default Realisations;