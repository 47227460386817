import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import section from "../img/section-img.png";
import {toastClick} from "../toast/CustomToast";
import {useParams} from "react-router-dom";

function Quizz() {

    const {id}=useParams();
    const [quizzInfo, setQuizzInfo] = useState({
        title:'',
        author:''
    });
    const [quizData, setQuizData] = useState([]);
    const [data, setData] = useState(null);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(-1);
    const [quizzResultat, setQuizzResultat] = useState([]);
    const shuffleArray = (array) => {
        return array.slice().sort(() => Math.random() - 0.5);
    };




    const checkAnswer = (e) => {
        let userAnswer = e.target;
        e.target.classList.add("correct");
        quizzResultat.push({
            questionId: quizData[questionNumber+1].questionId,
            answer: userAnswer.id
        });
        setQuizzResultat(quizzResultat);
        let allOptions = document.querySelectorAll(".quiz-container .option");
        allOptions.forEach((o) => {
            if (e.target.id != o.id)
                o.classList.remove("correct");
        });
    };

    const createQuestion = () => {
        let i = questionNumber + 1;
        setQuestionNumber(i);
        let timerDisplay = document.querySelector(".quiz-container .timer");
        timerDisplay.classList.remove("danger");
        let options = document.querySelector(".quiz-container .options");
        let question = document.querySelector(".quiz-container .question");

        timerDisplay.textContent = ``;

        options.innerHTML = "";
        question.innerHTML = `<span class='question-number' id='${quizData[i].questionId}'>${(i+1)}/${maxQuestions}</span>${quizData[i].question}`;

        const shuffledOptions = shuffleArray(quizData[i].options);

        shuffledOptions.forEach((o) => {
            const option = document.createElement("button");

            option.classList.add("option");
            option.id = o.id
            option.innerHTML = o.value;
            option.addEventListener("click", (e) => {
                checkAnswer(e);
            });
            options.appendChild(option);
        });
    };

    const retakeQuiz = () => {
        window.location.href = '/';
    };

    const displayQuizResult = async () => {
        let quizContainer = document.querySelector(".quiz-container");
        let quizResult = document.querySelector(".quiz-result");
        quizResult.style.display = "flex";
        quizContainer.style.display = "none";
        quizResult.innerHTML = "";

        let retakeBtn = document.createElement("button");
        retakeBtn.classList.add("retake-btn");
        retakeBtn.innerHTML = "Quizz Terminer";
        let preloader = document.getElementById("preloader");
        preloader.classList.remove('preloader-deactivate');
        const ip = await getIp();
        let requests = [];
        for(let question of data.questions){
            if(quizzResultat.length > 0){
                for (let quizzResultatKey of quizzResultat) {
                    if(question.id === quizzResultatKey.questionId){
                        for (let modelResponsesKey of question.modelResponses) {
                            if(modelResponsesKey.id==quizzResultatKey.answer){
                                requests.push({
                                    "id": null,
                                    "typeResponse": "BINAIRY",
                                    "values": [{
                                        "id": modelResponsesKey.id,
                                        "value": modelResponsesKey.value,
                                    }],
                                    "typeUser": "ANONYMOUS",
                                    "questionId": question.id,
                                    "sondageId": data.id,
                                    "ip": ip,
                                    "userAgent": navigator.userAgent,
                                    "dateTime": null,
                                });
                            }

                        }
                    }

                }
            }
        }
        axiosInstance().post('test/api/sondage/pass', requests)
            .then(function (response) {
                preloader.classList.add('preloader-deactivate');
                sessionStorage.clear();
                toastClick("Reponses enregistrees. Vous allez etre redirige sur la page d'acceuil dans 03 secondes apres fermeture de cette fenetre", 'success');

                setTimeout(() => {
                    window.location.href='/';
                }, 7000);
            })
            .catch(function (error) {
                preloader.classList.add('preloader-deactivate');
                toastClick("Une erreur est survenue lors de l'enregistrement des reponse de votre sondave. Verifier vos parametres de connexion", 'error');
                window.location.reload();
            });


        retakeBtn.addEventListener("click", retakeQuiz);
        quizResult.appendChild(retakeBtn);
    };

    const displayNextQuestion = () => {
        if (questionNumber > (maxQuestions-1)) {
            displayQuizResult();
            return;
        }else{
            if (quizzResultat.some(function (value) {return value.questionId === quizData[questionNumber].questionId})) {
                if (questionNumber >= (maxQuestions-1)) {
                    displayQuizResult();
                    return;
                }
                createQuestion();
            }else {
                toastClick("Choisir une reponse", 'info')
            }
        }

    };

    const getIp = async () =>{
        let ip ="";
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const ips = await response.json();
            ip = ips.ip;
        } catch (error) {
        }
        return ip;
    }


    const startQuizz = ()=>{
        setQuizzResultat([]);
        setQuestionNumber(-1);
        document.querySelector(".start-btn-container").style.display = "none";
        document.querySelector(".quiz-container").style.display = "block";
        document.title=quizzInfo.title
        createQuestion();
    }
    const passQuizz = async () => {
        let preloader = document.getElementById("preloader");
        preloader.classList.remove('preloader-deactivate');
        axiosInstance().get('test/api/sondage/' + id)
            .then(function (response) {
                let sondages = response.data;
                setData(sondages);
                setQuizzInfo({
                    title: sondages.name,
                    author: sondages.createUser
                });
                let quizDataFetched = [];
                for (let i = 0; i < sondages.questions.length; i++) {
                    const quiz = {
                        questionId: 0,
                        question: "",
                        options: [],
                        correct: "",
                    }
                    quiz.question = sondages.questions[i].libelle;
                    quiz.questionId = sondages.questions[i].id;
                    let options = [];
                    for (let j = 0; j < sondages.questions[i].modelResponses.length; j++) {
                        options.push({
                            id: sondages.questions[i].modelResponses[j].id,
                            value: sondages.questions[i].modelResponses[j].value,
                        });
                    }
                    quiz.options = options;
                    quizDataFetched.push(quiz);
                }
                setQuizData(quizDataFetched);
                setMaxQuestions(quizDataFetched.length);
                setQuizData(shuffleArray(quizDataFetched));
                setQuizzResultat([]);

                preloader.classList.add('preloader-deactivate');
            })
            .catch(function (error) {
                toastClick("Sondage non existant", "error");
                window.location.href="/";
            });
    }

    useEffect(
        ()=>{
            document.title='Quizz';
            passQuizz();
        },[]
    )


    return <section className="blog section" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>QUIZZ</h2>
                        <img src={section} alt="#" />
                            <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="start-btn-container">
                        <h2 >{quizzInfo.title}</h2>
                        <p >{quizzInfo.author}</p>
                        <button className="start-btn" onClick={()=>startQuizz()}>Commencer le Quizz</button>
                    </div>
                    <div className="quiz-container">
                        <h1>{quizzInfo.title}</h1>
                        <div className="timer"></div>
                        <h2 className="question"></h2>
                        <div className="options"></div>
                        <input className="next-btn" type="button" onClick={()=>displayNextQuestion()} value="Suivant" />
                    </div>
                    <div className="quiz-result"></div>
                </div>
            </div>
        </div>
    </section>;

}
export default Quizz;