import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../configurations/axiosInstance/AxiosInstance";
import { NavLink } from "react-router-dom";
import articleImg from "../../../img/cover/BMD-3398-760x400.png";

function ListArticle() {
    const [articles, setArticles] = useState([]);

    const fetchArticles = () => {
        axiosInstance().get('api/article/active/home')
            .then(function (response) {
                let data = response.data;
                let fetchedArticles = [];
                if (data != null) {
                    for (let i = 0; i < 3; i++) {
                        try {
                            if(data[i].status) {
                                fetchedArticles.push(
                                    <div className="col-lg-4 col-md-6 col-12" key={data[i].id}>
                                        <div className="single-news">
                                            <div className="news-head">
                                                <img src={data[i].phote == null ? articleImg : data[i].phote} alt="Image de couverture" />
                                            </div>
                                            <div className="news-body">
                                                <div className="news-content">
                                                    <div className="date">Auteur: {data[i].author}</div>
                                                    <h2><NavLink
                                                        to={"/read/" + data[i].id}>Titre: {data[i].title}</NavLink></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        } catch (e) {
                        }
                    }
                    setArticles(fetchedArticles);
                } else {
                    setArticles([]);
                }
            })
            .catch(function (error) {
            });
    }

    useEffect(() => {
        fetchArticles();
    }, []);

    return (
        articles.length === 0 ? (
            <></> // ou <div></div> si vous préférez ne pas renvoyer de contenu
        ) : (
            <section className="blog section" id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>ARTICLES</h2>
                                <img src="img/section-img.png" alt="#" />
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {articles}
                    </div>
                </div>
            </section>
        )
    );
}

export default ListArticle;