import React from "react";
import WorldMap from "./worldMap/WorldMap";

function Map() {

    return <div id="map" style={
        {
            margin: 'auto',
            width: '100%',
            height: '600px'
        }
    }>
        <WorldMap />
    </div>;

}

export default Map;