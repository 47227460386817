import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import section from "../img/section-img.png";
import {toastClick} from "../toast/CustomToast";
import {useParams} from "react-router-dom";

function QuizzList() {

    const [quizz, setQuizz] = useState([]);

    const fetchQuizz = ()=>{
        axiosInstance().get('test/api/sondage/anonyme')
            .then(function (response) {
                let data = response.data;
                if(data.length===0){
                    setQuizz([]);
                }else {
                    setQuizz(data);
                }
            })
            .catch(function (error) {
                setQuizz([]);
            });

    }
    useEffect(
        ()=>{
            fetchQuizz();
            document.title='Quizz';
        },[]
    )
    const startQuizz = (id)=>{
        window.location.href='/quizz/'+id;
    }


    return <section className="blog section" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>QUIZZ</h2>
                        <img src={section} alt="#" />
                            <p></p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="start-btn-container">
                        {quizz.map((quiz, index) => (
                            <div key={quiz.id} title={"Titre: " + quiz.name} className="btn">
                                <button className="start-btn" onClick={()=>startQuizz(quiz.id)}>Commencer le Quizz: {quiz.name}</button>
                                <hr />
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    </section>;

}
export default QuizzList;