import React from "react";

function Slider() {
    return <section className="slider">
        <div className="hero-slider">
            <div className="single-slider" style={
                {
                    backgroundImage: "url(img/cover/banner1.png)"
                }
            }>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text">
                                <h1>YOUTH<span>F</span>P <span></span></h1>
                                <p>YouthFP est la plateforme de santé sexuelle et reproductive pour les jeunes au
                                    Cameroun !</p>
                                <div className="button">
                                    <a href="/register" className="btn clignoter">S'enregistrer</a>
                                    <a href="https://client.youthfp.cm/login" className="btn primary">Se Connecter</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="text">
                                <div className="image">
                                    <img style={
                                        {
                                            width: 'auto',
                                            height: '330px'
                                        }
                                    } src="img/cover/15.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-slider" style={
                {
                    backgroundImage: "url(img/cover/banner.jpg)"
                }
            }>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text">
                                <h1>Formation et restructuration des <span>Administrateurs</span> de la
                                    plateforme <span>YOUTHFP</span></h1>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="text">
                                <div className="image">
                                    <img style={
                                        {
                                            width: 'auto',
                                            height: '330px'
                                        }
                                    } src="img/cover/11.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-slider" style={
                {
                    backgroundImage: "url(img/cover/banner2.png)"
                }
            }>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text">
                                <h1>Campagne de <span>Sensibilisation</span> et d'offre de services
                                    de <span>SSR/PF</span></h1>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="text">
                                <div className="image">
                                    <img style={
                                        {
                                            width: 'auto',
                                            height: '330px'
                                        }
                                    } src="img/cover/cover.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;

}

export default Slider;