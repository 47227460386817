import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../toast/CustomToast";

function AddDenonciations() {

    const [message, setMessage] = useState("");

    const checkTextareaValue = (event) => {
        let message = event.target.value;
        setMessage(message.trim());
        if (message.trim().length < 10) {
            document.getElementById('btnsubmit').style.display = 'none';
        } else {
            document.getElementById('btnsubmit').style.display = 'block';
        }
    };
    useEffect(
        ()=>{
            document.title='Denonciation';
        }
    )

    const submitDenonciation = (event) => {
        event.preventDefault();
        document.getElementById('preloader').classList.remove('preloader-deactivate');

        axiosInstance().post('denonciation',
            {
                contenu: message,
                id:null,
                dateDenonciation: Date.now(),
                utilisateur:null
            }
        )
            .then(function (response) {
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick("Success","success");

            })
            .catch(function (error) {
                document.getElementById('message').value = message;
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick("Echec","error");
            });
    };

    return (
        <section className="services section" style={{background: '#ffffff00'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>DENONCIATION</h2>
                            <img src="/img/section-img.png" alt="#" />
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={submitDenonciation}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            onKeyUp={checkTextareaValue}
                                            placeholder="Votre Denonciation"
                                            rows="10"
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group login-btn">
                                        <button
                                            className="btn"
                                            style={{ display: "none" }}
                                            id="btnsubmit"
                                            type="submit"
                                        >
                                            Denoncer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AddDenonciations;
